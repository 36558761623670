import React from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const ServicesDetailsArea = () => {
  const handleDownload = () => {
    // Access the PDF from the public folder
    const pdfPath = process.env.PUBLIC_URL + "/BeSureBuild-Snag-Checklist.pdf";

    // Create a temporary link element for downloading
    const link = document.createElement("a");
    link.href = pdfPath;
    link.setAttribute("download", "BeSureBuild-Snag-Checklist.pdf"); // Set a custom filename if desired
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link element after download
    document.body.removeChild(link);
  };
  return (
    <>
      <section className="services__details pt-50 pb-50">
        <div className="container">
          <div className="row">
            {/* Main Content Area */}
            <div className="col-xl-8 col-lg-8">
              <div className="services__text">
                <h2 className="mb-20">
                  New home professional snagging service
                </h2>
                <p>
                  BeSure Build offers new homeowners peace of mind by ensuring
                  their property meets the high standards they expect from their
                  builder. Ease the stress and complexity of buying a new home
                  with our professional New Build Snagging service.
                </p>
              </div>
              <div className="services__list mb-40">
                <ul>
                  <li>
                    We guarantee that our snagging survey report is delivered
                    within 48 hours of completing the inspection at your
                    property.
                  </li>
                  <li>
                    The average survey takes 3 to 6 hours, allowing us to
                    complete it on a day that suits your schedule.
                  </li>
                  <li>
                    A quick turnaround on snagging is vital for getting any
                    flaws or defects in the property rectified as soon as
                    possible.
                  </li>
                  <li>
                    We also fly a drone above your home to ensure that any
                    issues are not missed.
                  </li>
                  <li>
                    A thermal camera is also used to identify issues that our
                    other cameras may not pick up, such as poor insulation, cold
                    spots around windows ect.
                  </li>
                </ul>
              </div>
              <div className="services__img mb-45 w-img">
                <img
                  src="assets/img/services/details/services-02.jpg"
                  alt="new build snagging"
                />
              </div>
              <div className="services__text">
                <h2 className="mb-20">
                  Rental snagging and itemised contents portfolio service
                </h2>
                <p>
                  BeSure Build provides peace of mind when preparing your
                  property for letting. We offer visits to document and itemize
                  contents, creating a detailed snagging list of any damages and
                  necessary corrective actions before your next tenancy.
                </p>
              </div>
              <div className="services__list mb-40">
                <ul>
                  <li>
                    Comprehensive rental snagging and itemised contents
                    portfolio for hassle-free property management.
                  </li>
                  <li>
                    Identify and address damages with a clear action plan before
                    your next letting.
                  </li>
                  <li>
                    Ensure your property is fully prepared and protected, giving
                    you confidence as a landlord.
                  </li>
                </ul>
                <div className="services__img mb-15 w-img">
                  <img
                    src="assets/img/services/details/services-03.jpg"
                    alt="rental snagging"
                  />
                </div>
              </div>
            </div>
            {/* Sidebar Area */}
            <div className="col-xl-4 col-lg-4">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "20px",
                }}
              >
                <Link to="/contact" className="z-btn ">
                  Get a free quote
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
              <div className="faq__accordion p-relative">
                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="card-header accordion-header" id="acc_1">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_1"
                          aria-expanded="true"
                          aria-controls="collapse_1"
                        >
                          What is snagging?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_1"
                      className="collapse show"
                      aria-labelledby="acc_1"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Snagging is an inspection carried out on a property to
                          identify any defects or issues that a buyer or
                          investor would like to have corrected. These problems
                          are recorded as ‘snags’ and presented to the builders
                          or developer with a request for them to be addressed.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_2">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_2"
                          aria-expanded="true"
                          aria-controls="collapse_2"
                        >
                          What does my snag report contain?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_2"
                      className="collapse"
                      aria-labelledby="acc_2"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Your snagging report includes a detailed list of any
                          defects or issues identified during the inspection,
                          along with the specific location within the property.
                          Each report is linked British Standards, ensuring that
                          all issues are identified and reported correctly.
                          Photographs are provided to visually document each
                          problem. Information on the trade or specialist who
                          should be assigned the work to ensure the issues are
                          properly resolved.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_3">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_3"
                          aria-expanded="true"
                          aria-controls="collapse_3"
                        >
                          How long does a snagging inspection take?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_3"
                      className="collapse"
                      aria-labelledby="acc_3"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          A snagging inspection will take around 3 to 6 hours.
                          This allows us to complete the inspection in a single
                          day, at a time that suits your schedule. We guarantee
                          that your snagging report will be delivered within 48
                          hours of completing the inspection at your property.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_4">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_4"
                          aria-expanded="true"
                          aria-controls="collapse_4"
                        >
                          Which areas do you cover?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_4"
                      className="collapse"
                      aria-labelledby="acc_4"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          We cover the the North West of England, including
                          Preston, Blackpool, Lancaster, Manchester, Liverpool,
                          and surrounding areas. If you are unsure whether we
                          cover your area, please get in touch with us to
                          discuss your requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_5">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_5"
                          aria-expanded="true"
                          aria-controls="collapse_5"
                        >
                          Pricing
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_5"
                      className="collapse"
                      aria-labelledby="acc_5"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          <b>
                            These prices are averages depending on size of
                            house:
                          </b>
                        </p>
                        <ul>
                          <li>&#8226; 2 Bed House: £300</li>
                          <li>&#8226; 3 Bed House: £350</li>
                          <li>&#8226; 4 Bed House: £400</li>
                          <li>&#8226; 5 Bed House: £450</li>
                          <li>&#8226; 6 Bed House: £500</li>
                          <li>
                            &#8226; Revisits are charged at half the initial
                            price
                          </li>

                          <li>&#8226; Other: Contact for quote</li>
                        </ul>
                        <div className="mt-5"></div>
                        <p style={{ marginBottom: "0px" }}>
                          <b>Rental Pricing</b>
                        </p>
                        <ul>
                          <li>&#8226; Rental properties start from £250</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="services__action grey-bg-18 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleDownload}
              >
                <i>
                  <AiOutlineFilePdf style={{ marginRight: "10px" }} /> Download
                  Free Checklist
                </i>
              </div>
              <div className="services__img w-img">
                <img src="assets/img/services/RPSA_logo.png" alt="RPSA logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesDetailsArea;
