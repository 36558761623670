import React from 'react';
import { FaCheck } from 'react-icons/fa';

export const HomeCapabilities = () => {
  return (
    <>
      <section className='capabilities__area p-relative black-bg-2 pt-180 pb-155 fix'>
        <div
          className='capabilities__thumb p-absolute'
          style={{
            background: `url(assets/img/capabilities/newbuildhome.webp)`,
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            height: '100%'
          }}
        ></div>
        <div className='capabilities__shape p-absolute wow fadeInLeft'>
          <img
            src='assets/img/capabilities/capabilities-shape.png'
            alt='shape'
          />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-6'>
              <div
                className='capabilities__content wow fadeInUp'
                data-wow-delay='.4s'
              >
                <div className='section__title section__title-2 mb-60 ml-20'>
                  <h2 className='white-color'>New home snagging</h2>
                </div>
                <div className='capabilities__list ml-20'>
                  <ol>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Choose your survey date/time
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      We conduct the survey (~3-6hrs)
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Receive the report within 48 hours
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Hand the report to your developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const HomeCapabilitiesReverse = () => {
  return (
    <>
      <section className='capabilities__area p-relative black-bg-2 pt-180 pb-155 fix'>
        <div
          className='capabilities__thumb p-absolute'
          style={{
            background: `url(assets/img/capabilities/construction-survey.webp)`,
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            height: '100%',
            left: 0,
            right: 'auto'
          }}
        ></div>
        <div
          className='capabilities__shape p-absolute wow fadeInRight'
          style={{ right: 0, left: 'auto', transform: 'scaleX(-1)' }}
        >
          <img
            src='assets/img/capabilities/capabilities-shape.png'
            alt='shape'
          />
        </div>
        <div className='container'>
          <div className='row justify-content-end'>
            <div className='col-xl-5 col-lg-6'>
              <div
                className='capabilities__content wow fadeInUp'
                data-wow-delay='.4s'
              >
                <div className='section__title section__title-2 mb-60 ml-20'>
                  <h2 className='white-color'>Construction services</h2>
                </div>
                <div className='capabilities__list ml-20'>
                  <ol>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Final inspections
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Dry lining plot checks
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Invoice measure checks
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Stage inspection reports
                    </li>
                    <li>
                      <FaCheck className='capabilities__icon' />
                      Training & support
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
